import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import Papa from 'papaparse';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";

// Set the path for the custom pin image
const customIcon = new L.Icon({
  iconUrl: `${process.env.PUBLIC_URL}/UI/pin.png`,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
});

// Styled Components
const MapContainerWrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
`;

const OverlayImage = styled.img`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: auto;
  z-index: 1000;
  pointer-events: none;

  /* Show only on mobile devices */
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;


const BackButton = styled.img`
  position: fixed;
  bottom: 60px; /* Position it above the overlay */
  left: 20px;
  width: 150px;
  height: auto;
  z-index: 1001; /* Ensure it appears above the overlay */
  cursor: pointer;
`;

const createClusterCustomIcon = (cluster) => {
  return L.divIcon({
    html: `
      <div style="position: relative;">
        <img src="${process.env.PUBLIC_URL}/UI/pin.png" style="width: 32px; height: 32px;" />
        <span style="
          position: absolute;
          top: 0;
          right: 0;
          color: white;
          background: rgba(0, 0, 0, 0.5);
          padding: 2px 5px;
          border-radius: 50%;
          font-weight: bold;
          font-size: 12px;">
          ${cluster.getChildCount()}
        </span>
      </div>
    `,
    className: 'custom-cluster-icon',
    iconSize: [32, 32],
  });
};

const SetViewOnNearestStore = ({ nearestStore }) => {
  const map = useMap();
  useEffect(() => {
    if (nearestStore) {
      map.setView([nearestStore.lat, nearestStore.lng], 15); // Set view with zoom level 15
    }
  }, [nearestStore, map]);
  return null;
};

const StoreLocator = () => {
  const [locations, setLocations] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [nearestStore, setNearestStore] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userLoc = [latitude, longitude];
          setUserLocation(userLoc);

          if (locations.length > 0) {
            findNearestStore(userLoc, locations);
          }
        },
        () => {
          console.log("User location not available. Using default location.");
          setUserLocation([-7.0188158, 108.6380562]);
        }
      );
    } else {
      console.log("Geolocation not supported by this browser.");
      setUserLocation([-7.0188158, 108.6380562]);
    }
  }, [locations]);

  useEffect(() => {
    Papa.parse(`${process.env.PUBLIC_URL}/data/milo.csv`, {
      download: true,
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const formattedData = result.data
          .map((location) => ({
            name: location.name,
            address: location.address,
            lng: parseFloat(location.lng),
            lat: parseFloat(location.lat),
          }))
          .filter((location) => !isNaN(location.lat) && !isNaN(location.lng));

        setLocations(formattedData);

        if (userLocation) {
          findNearestStore(userLocation, formattedData);
        }
      },
    });
  }, []);

  const findNearestStore = (userLoc, stores) => {
    let nearest = null;
    let minDistance = Infinity;
    
    ReactGA.event({
      category: "END SCREEN",
      action: "ENGAGE_TAP_TO_ALLOW_LOCATION",
    });

    stores.forEach((store) => {
      const distance = L.latLng(userLoc).distanceTo(L.latLng([store.lat, store.lng]));
      if (distance < minDistance) {
        minDistance = distance;
        nearest = store;
        
      }
    });

    setNearestStore(nearest);
  };

  return (
    <MapContainerWrapper >
      <MapContainer 
        center={userLocation || [-7.0188158, 108.6380562]}
        zoom={10}
        style={{ height: "100%", width: "100%" }}
        attributionControl={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        
        {nearestStore && <SetViewOnNearestStore nearestStore={nearestStore} />}

        <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
          {locations.map((location, index) => (
            <Marker
              key={index}
              position={[location.lat, location.lng]}
              icon={customIcon}
            >
              <Popup maxWidth={200} minWidth={150}>
                <b>{location.name}</b><br />
                <p style={{ fontSize: '12px', margin: 0 }}>{location.address}</p>
                
                {/* Add Google Maps link for navigation */}
                <a 
                  href={`https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}`} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  style={{ color: '#007BFF', textDecoration: 'underline', fontSize: '12px' }}
                >
                  Navigate with Google Maps
                </a>
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>

      </MapContainer>

      {/* Back button, placed above the overlay on the left */}
      <BackButton 
        src={`${process.env.PUBLIC_URL}/button/back.png`} 
        alt="Back Button" 
        onClick={() => {
          ReactGA.event({
            category: "END SCREEN",
            action: "ENGAGE_TAP_BACK",
          });
          navigate('/cta'); // Navigate after triggering the event
        }}
      />

      {/* Bottom overlay image, only visible on mobile devices */}
      <OverlayImage 
        src={`${process.env.PUBLIC_URL}/background/overlayMap.png`} 
        alt="Milo Overlay" 
      />
    </MapContainerWrapper>
  );
};

export default StoreLocator;
