import Phaser from "phaser";
import { Physics } from "phaser";
import * as faceMesh from '@mediapipe/face_mesh';
import { Camera } from '@mediapipe/camera_utils';
import ReactGA from "react-ga4";

const {Matter: M} = Physics;
const{Matter} = M;

export default class GameScene extends Phaser.Scene {
    constructor() {
        super({
            key: "GameScene"
        });
        this.videoRef = null;
        this.faceMeshRef = null;
        this.blinkThreshold = 0.15;
        this.succFrame = 1;
        this.countFrame = 0;
        this.frameCounter = 0; // Define frameCounter here
        this.isGrounded = true; // Track whether the character is grounded

    }
    preload() {
        this.faceMeshRef = new faceMesh.FaceMesh({
            locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
            modelComplexity: 0, // You can adjust complexity for performance
            useWebGL: true // Ensure WebGL is being used if available
        });
        
    
        this.faceMeshRef.setOptions({
            maxNumFaces: 1,
            refineLandmarks: true,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5,
            background_throttling:false
        });
    
        this.faceMeshRef.onResults((results) => this.onResults(results));
    }
    create() {
        const {Bodies} = Matter;

        //Audio
        this.jumpSFX = this.sound.add('jumpSFX');
        this.slideSFX = this.sound.add('slideSFX');
        this.rollSFX = this.sound.add('rollSFX', { loop: true }); // Loop the roll sound
        this.backgroundMusic = this.sound.add('backgroundMusic', { loop: true, volume: 0.3 });

        // Play background music
        this.backgroundMusic.play();

        //instruction 
        this.showInstructionOverlay();
        
        // Initialize blink detection
            this.initBlinkDetection();
        

        // Camera setup for full-height mirrored webcam video
        const video = this.add.video(0, 0).setOrigin(0.5, 0.5).setScrollFactor(0).setVisible(true);

        video.once('created', () => {
            const videoAspectRatio = video.width / video.height;
    
            // Set video height to match the screen height, adjust width proportionally
            const newHeight = window.innerHeight;
            const newWidth = newHeight * videoAspectRatio;
    
            video.setDisplaySize(newWidth, newHeight);
    
            // Mirror the video horizontally
            video.setFlipX(true);
    
            // Center the video in the viewport
            video.setPosition(this.scale.width / 2, this.scale.height / 2);
            video.setVisible(true);
        });
    
        // Access the webcam and stream it into Phaser's video object
        navigator.mediaDevices.getUserMedia({ video: true, audio: false })
        .then((stream) => {
            // Load the video stream directly into the Phaser video object
            video.loadMediaStream(stream);
            video.play(true); // Phaser's play method with `true` for looped playback if needed
            ReactGA.event({
                category: "GAME SCREEN",
                action: "ENGAGE_TAP_TO_ALLOW_CAMERA",
            });
        })
        .catch((err) => {
            console.error("Error accessing webcam:", err);
        });
    
    
        // Adjust position on window resize
        this.scale.on('resize', (gameSize) => {
            const { width, height } = gameSize;
            video.setPosition(width / 2, height / 2);
        });


        //Characters
        //Character
        this.character = this.matter.add.sprite(window.innerWidth / 2, 0, 'character');
        this.character.setScale(0.3)
        this.character.setDepth(10)

        const characterBody = Bodies.rectangle(this.character.x, this.character.y + this.character.y + this.character.displayHeight/2, 100, 100);

        this.character.setExistingBody(characterBody)
        this.character.setFixedRotation()

        //Animation

        this.anims.create({
            key:"jump",
            frames: this.anims.generateFrameNumbers("character", {frames:[2]}),
            frameRate:2,
            repeat:0
        })

        this.anims.create({
            key:"slide",
            frames: this.anims.generateFrameNumbers("character", {frames:[0]}),
            frameRate:1,
            repeat: 0
        })

        // Set up collision event listener
        this.matter.world.on('collisionstart', (event, bodyA, bodyB) => {
            if ((bodyA === this.character.body && bodyB === this.milo?.body) ||
                (bodyB === this.character.body && bodyA === this.milo?.body)) {

                this.collectMilo();
                // Collectible item based on flavor
                

            }
        });

        // Milo collectible - Set above the player and make it a sensor
        this.time.addEvent({
            delay: 1500, // 3000ms = 3 seconds
            callback: this.spawnMilo,
            callbackScope: this,
            loop: true
        });
        
        //Environments
        //Platform1
        this.platform = this.matter.add.image(window.innerWidth / 2, window.innerHeight- 40, 'rock');
        this.platform.setScale(0.7)
        this.platform.setDepth(1)

        //Hitbox Platform
        const platformBody = Bodies.rectangle(this.platform.x, this.platform.y, this.platform.displayWidth, this.platform.displayHeight/2 + 30)
        this.platform.setExistingBody(platformBody);
        this.platform.setStatic(true);
        

        //Platform1
        this.platform2 = this.matter.add.image(this.platform.x + this.platform.displayWidth, this.platform.y, 'rock');
        this.platform2.setScale(0.7)
        this.platform2.setDepth(1)

        //Hitbox Platform
        const platform2Body = Bodies.rectangle(this.platform2.x, this.platform2.y, this.platform2.displayWidth, this.platform2.displayHeight/2 + 30)
        this.platform2.setExistingBody(platform2Body);
        this.platform2.setStatic(true);

        //Land
        this.land = this.add.image(window.innerWidth / 2, window.innerHeight- 193, 'land');
        this.land.setScale(0.7)
        this.land.setDepth(2)

        //Land
        this.land2 = this.add.image(this.land.x + this.land.displayWidth, window.innerHeight- 193, 'land');
        this.land2.setScale(0.7)
        this.land2.setDepth(2)

        //Building
        this.building = this.add.image(window.innerWidth / 2, window.innerHeight - 350, 'building');
        this.building.setDepth(1)
        this.building.setScale(1.3)
        this.building.setScrollFactor(0.1)

        //Building
        this.building2 = this.add.image(this.building.x + this.building.displayWidth, window.innerHeight - 350, 'building');
        this.building2.setDepth(1)
        this.building2.setScale(1.3)
        this.building2.setScrollFactor(0.1)


        //CameraControls
        this.cameras.main.startFollow(this.character)
        this.cameras.main.followOffset.x = -100; // Adjust this value as needed to shift the camera to the right
        this.cameras.main.setBounds(undefined, 0, undefined, this.platform.displayHeight)

        //Helper Keyboard
        this.cursors = this.input.keyboard.createCursorKeys();

        // Timer variable setup
        this.timerValue = 15; // 15-second countdown timer

        //Score Setup
        this.scoreValue = 0;


        //UI
        // Logo (also adjusted to top for consistency)
        this.logo = this.add.image(this.scale.width * 0.2, this.scale.height * 0.1, 'logo'); // Position from the top
        this.logo.setScale(0.6);
        this.logo.setDepth(10);
        this.logo.setScrollFactor(0);

        //Score UI
        // Score UI image
        this.scoreImage = this.add.image(this.scale.width * 0.8, this.scale.height * 0.1, 'score');
        this.scoreImage.setScale(0.7);
        this.scoreImage.setDepth(10);
        this.scoreImage.setScrollFactor(0);
        
        // Score text, centered on score UI image
        this.scoreText = this.add.text(this.scoreImage.x, this.scoreImage.y + 10, `${this.scoreValue}`, {
            fontSize: '30px',
            color: '#382215',
            fontStyle: 'bold'
        }).setOrigin(0.5);
        this.scoreText.setDepth(11);
        this.scoreText.setScrollFactor(0);

        // Create the time UI image
        this.timeImage = this.add.image(this.scale.width * 0.8, this.scale.height * 0.22, 'time');
        this.timeImage.setScale(0.6);
        this.timeImage.setDepth(10);
        this.timeImage.setScrollFactor(0);

        // Display the countdown timer text, centered on the time image
        this.timerText = this.add.text(this.timeImage.x, this.timeImage.y + 10, `${this.timerValue}`, {
            fontSize: '24px',
            color: '#382215',
            fontStyle: 'bold'
        }).setOrigin(0.5); // Center the text within its position
        this.timerText.setDepth(11);
        this.timerText.setScrollFactor(0);



        // Delay to start the game timer after instruction ends
        this.events.once('instructionEnd', () => {
            this.startGameTimer();
        });

        //debug
        // this.input.on('pointerdown', () => {
        //     this.jumpCharacter(); // Manually trigger jump on tap for testing
        // });

        // Add EAR text below the timer
        // this.earText = this.add.text(this.timeImage.x, this.timeImage.y + 40, `EAR: 0.00`, {
        //     fontSize: '20px',
        //     color: '#382215',
        //     fontStyle: 'bold'
        // }).setOrigin(0.5);
        // this.earText.setDepth(11);
        // this.earText.setScrollFactor(0);

        // Add landmark detection status text below EAR
        // this.landmarkStatusText = this.add.text(this.timeImage.x, this.timeImage.y + 70, `Landmark: Not Detected`, {
        //     fontSize: '20px',
        //     color: '#382215',
        //     fontStyle: 'bold'
        // }).setOrigin(0.5);
        // this.landmarkStatusText.setDepth(11);
        // this.landmarkStatusText.setScrollFactor(0);
        
    }

    //blink eyes
    initBlinkDetection() {
        this.videoRef = document.createElement("video");
        this.videoRef.setAttribute("playsInline", true);
        this.videoRef.setAttribute("muted", true); // Mute if needed for silent processing
    
        const camera = new Camera(this.videoRef, {
            onFrame: async () => {
                    if (this.faceMeshRef && this.frameCounter % 3 === 0) {
                        await this.faceMeshRef.send({ image: this.videoRef });
                    }
                    this.frameCounter++;
            },
            width: 640,
            height: 480,
        });
        
        
        camera.start();
    }
    
    // Calculate Euclidean distance
    euclideanDistance(point1, point2) {
        return Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
    }

    // Calculate Eye Aspect Ratio (EAR) for blink detection
    calculateEAR(eye) {
        const vertical1 = this.euclideanDistance(eye[1], eye[5]);
        const vertical2 = this.euclideanDistance(eye[2], eye[4]);
        const horizontal = this.euclideanDistance(eye[0], eye[3]);
        return (vertical1 + vertical2) / (2.0 * horizontal);
    }

    onResults(results) {
            if (results.multiFaceLandmarks && results.multiFaceLandmarks.length > 0) {
                const landmarks = results.multiFaceLandmarks[0];
                const leftEyeLandmarks = [landmarks[362], landmarks[385], landmarks[387], landmarks[263], landmarks[373], landmarks[380]];
                const rightEyeLandmarks = [landmarks[33], landmarks[160], landmarks[158], landmarks[133], landmarks[153], landmarks[144]];
                const leftEAR = this.calculateEAR(leftEyeLandmarks);
                const rightEAR = this.calculateEAR(rightEyeLandmarks);
                const avgEAR = (leftEAR + rightEAR) / 2;

                // Update the EAR text
                // this.earText.setText(`EAR: ${avgEAR.toFixed(2)}`);

                // Update landmark status text
                // this.landmarkStatusText.setText('Landmark: Detected');

                if (avgEAR < this.blinkThreshold) {
                    this.countFrame += 1;
                } else {
                    if (this.countFrame >= this.succFrame) 
                        this.jumpCharacter();
                    
                    this.countFrame = 0;
                }
            } else {
                // If landmarks are not detected, update status text
                // this.landmarkStatusText.setText('Landmark: Not Detected');
            }
        }
    

    jumpCharacter() {
        // console.log('jump')
        if (this.character?.body && Math.abs(this.character.body.velocity.y) < 0.05) {
            this.character.setVelocityY(-10);
            this.jumpSFX.play();
            
            // Send a custom event
            ReactGA.event({
                category: "GAME SCREEN",
                action: "ENGAGE_BLINKING_EYE",
            });
        }
    }
    

    showInstructionOverlay() {
        // Create a gradient texture for the overlay
        const gradientWidth = this.scale.width;
        const gradientHeight = this.scale.height;

        // Create a temporary canvas to draw the gradient
        const gradientCanvas = this.textures.createCanvas('gradientOverlay', gradientWidth, gradientHeight);
        const context = gradientCanvas.context;

        // Create a gradient that goes from transparent at the top to black at the bottom
        const gradient = context.createLinearGradient(0, 0, 0, gradientHeight);
        gradient.addColorStop(0, 'rgba(0, 0, 0, 0)'); // Transparent at the top
        gradient.addColorStop(1, 'rgba(0, 0, 0, 0.7)'); // Dark at the bottom

        // Fill the canvas with the gradient
        context.fillStyle = gradient;
        context.fillRect(0, 0, gradientWidth, gradientHeight);

        // Refresh the texture so that Phaser uses the updated canvas
        gradientCanvas.refresh();

        // Add the gradient overlay as a sprite and set it to full screen
        const overlay = this.add.image(0, 0, 'gradientOverlay').setOrigin(0, 0);
        overlay.setDepth(15);
        overlay.setScrollFactor(0); // Keep the overlay static

        // Add the instruction image
        const instructionImage = this.add.image(this.scale.width / 2, this.scale.height / 2 + 200, 'instruction');
        instructionImage.setScale(0.4);
        instructionImage.setDepth(16);
        instructionImage.setScrollFactor(0); // Make instruction image static

        // After 3 seconds, destroy the overlay and instruction image
        this.time.delayedCall(3000, () => {
            overlay.destroy();
            instructionImage.destroy();
            gradientCanvas.destroy(); // Clean up the gradient texture
            this.events.emit('instructionEnd'); // Emit an event to start the timer
        }, [], this);
    }

    startGameTimer() {
        // Start a 15-second countdown timer using this.sys.time to access the Time plugin
        this.gameTimer = this.sys.time.addEvent({
            delay: 1000, // 1-second intervals
            callback: () => {
                this.timerValue -= 1; // Decrease timer by 1 each second
                this.timerText.setText(`${this.timerValue}`); // Update timer display
    
                // End the game when the timer reaches zero
                if (this.timerValue <= 0) {
                    this.endGame();
                }
            },
            callbackScope: this,
            repeat: this.timerValue - 1 // Run until timerValue reaches 0
        });
    }
    
    
    endGame() {
        // Stop the faceMesh processing and cleanup
        if (this.faceMeshRef) {
            this.faceMeshRef.close(); // Stop any processing from faceMesh
            this.faceMeshRef = null;
        }
        if (this.videoRef) {
            this.videoRef.pause(); // Stop the video stream
            this.videoRef.srcObject = null;
            this.videoRef = null;
        }

    
        // Create a gradient texture for the overlay
        const gradientWidth = this.scale.width;
        const gradientHeight = this.scale.height;
    
        // Create a temporary canvas to draw the gradient
        const gradientCanvas = this.textures.createCanvas('endGradientOverlay', gradientWidth, gradientHeight);
        const context = gradientCanvas.context;
    
        // Create a gradient that goes from transparent at the top to black at the bottom
        const gradient = context.createLinearGradient(0, 0, 0, gradientHeight);
        gradient.addColorStop(0, 'rgba(0, 0, 0, 0)'); // Transparent at the top
        gradient.addColorStop(1, 'rgba(0, 0, 0, 0.7)'); // Dark at the bottom
    
        // Fill the canvas with the gradient
        context.fillStyle = gradient;
        context.fillRect(0, 0, gradientWidth, gradientHeight);
    
        // Refresh the texture so that Phaser uses the updated canvas
        gradientCanvas.refresh();
    
        // Add the gradient overlay as a sprite and set it to full screen
        const overlay = this.add.image(0, 0, 'endGradientOverlay').setOrigin(0, 0);
        overlay.setDepth(15);
        overlay.setScrollFactor(0); // Keep the overlay static
    
        // Add the end score card at the center of the screen
        const endScoreCard = this.add.image(this.scale.width / 2, this.scale.height / 2, 'endScore');
        endScoreCard.setScale(0.4);
        endScoreCard.setDepth(16);
        endScoreCard.setScrollFactor(0); // Keep it static
    
        // Display the final score text on the end score card
        const finalScoreText = this.add.text(this.scale.width / 2, this.scale.height / 2 + 55, `${this.scoreValue}`, {
            fontSize: '36px',
            color: '#ffffff',
            fontStyle: 'bold'
        }).setOrigin(0.5); // Center the text on the card
        finalScoreText.setDepth(17);
        finalScoreText.setScrollFactor(0);
    
        // Restart the scene after a delay
        this.sys.time.delayedCall(3000, () => {
            // console.log("Emitting navigateToCTA event...");
            this.game.events.emit("navigateToCTA"); // Emit the custom event
        });
    }
    
    
    update() {
        this.character.setVelocityX(5);
    
        // Check if character is grounded (velocity.y close to zero)
        if (Math.abs(this.character.body.velocity.y) < 0.05) {
            if (!this.rollSFX.isPlaying) {
                this.rollSFX.play();
            }
        } else {
            // Stop the roll sound when in the air (jumping or falling)
            if (this.rollSFX.isPlaying) {
                this.rollSFX.stop();
            }
        }
    
        // Watch for map bounds
        if (this.character.x > this.platform2.x) {
            this.platform.x += this.platform.displayWidth;
            this.platform2.x += this.platform2.displayWidth;
            this.land.x += this.land.displayWidth;
            this.land2.x += this.land2.displayWidth;
        }
    
        // Watch for environment bounds
        if (this.character.x * 0.1 > this.building2.x + (this.building2.x * 0.1)) {
            this.building.x += this.building.displayWidth;
            this.building2.x += this.building2.displayWidth;
        }
    
        // Play animations based on movement
        if (this.character.body.velocity.y < -0.01) {
            // Character is moving up (jumping)
            this.character.play({ key: 'jump', repeat: 0 }, true);
        } else if (this.character.body.velocity.y > 0.01) {
            // Character is moving down (falling)
            this.character.play({ key: 'slide' }, true);
        } else {
            // Character is grounded (standing or sliding)
            this.character.play({ key: 'slide' }, true);

        }
    }
    

    spawnMilo() {
        const { Bodies } = Matter; 

        for (let i = 0; i < 2; i++) {
            const randomOffset = Math.random() * 400 - 200;
            const miloX = this.character.x + 600 + randomOffset;
            const miloY = window.innerHeight - 340;
    
            const milo = this.matter.add.sprite(miloX, miloY, 'milo', null, {
                isStatic: true,
                isSensor: true
            });
            milo.setScale(0.13);
            milo.setDepth(5);

            // Create a smaller custom body for the milo collectible
            const miloBody = Bodies.rectangle(milo.x, milo.y, 70, 70); // Set to a smaller size
            milo.setExistingBody(miloBody);
            milo.setFixedRotation();
            milo.setStatic(true);
            milo.setSensor(true);
            
            // Add collision detection for this specific Milo item
            this.matter.world.on('collisionstart', (event, bodyA, bodyB) => {
                if ((bodyA === this.character.body && bodyB === milo.body) ||
                    (bodyB === this.character.body && bodyA === milo.body)) {
                    this.collectMilo(milo); // Pass the specific Milo object
                }
            });
        }
    }
    
    

    collectMilo(milo) {
        const urlParams = new URLSearchParams(window.location.search);
        const flavor = urlParams.get('flavor') || 'chocolate'; // Default to chocolate if no parameter
        this.scoreValue += 1;
        this.scoreText.setText(`${this.scoreValue}`);
    
        // Play the munch sound effect
        this.sound.play('munch');
    
        // Show the splash effect
        const splash = this.add.image(milo.x, milo.y, 'splash');
        splash.setScale(0.13);
        splash.setDepth(6); // Ensure it appears above other elements if needed
    
        // Hide the `Milo` object after collection
        milo.setVisible(false);
        milo.setActive(false);
        milo.destroy(); // Destroy the specific Milo object
    
        // Fade out the splash after a short delay and then remove it
        this.tweens.add({
            targets: splash,
            alpha: 0,
            duration: 500, // Adjust duration for how long the splash is visible
            onComplete: () => splash.destroy() // Remove splash after fade out
        });
    
        // Google Analytics event for collecting Milo
        ReactGA.event({
            category: "GAME SCREEN",
            action: "ENGAGE_HIT_COLLACT_BISCUIT",
        });

        if (flavor === 'vanilla') {
            // Send a custom event
            ReactGA.event({
                category: "GAME SCREEN",
                action: "ENGAGE_HIT_COLLACT_VANILLA_BISCUIT",
            });
        } else {
            // Send a custom event
            ReactGA.event({
                category: "GAME SCREEN",
                action: "ENGAGE_HIT_COLLACT_CHOCOLATE_BISCUIT",
            });
        }
    }
    
    
}
