import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";

// Keyframes for the pop-up animation
const popUpAnimation = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url('/background/BG.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;

  @media (min-width: 520px) {
    width: calc(100vh * 9 / 16);
    height: 100vh;
    margin: auto;
  }
`;

// Full overlay for the copy image with animation
const CopyOverlay = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
  animation: ${popUpAnimation} 0.8s ease-out;
`;

// Overlay areas for left and right halves
const ClickableArea = styled.div`
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: 2;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Optional hover effect */
  }
`;

const LeftArea = styled(ClickableArea)`
  left: 0;
`;

const RightArea = styled(ClickableArea)`
  right: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 2vw;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 2vw;
  z-index: 3; /* Ensure buttons are above the overlay image */

  @media (min-width: 768px) {
    bottom: 10%;
    gap: 1.5vw;
  }
`;

const Button = styled.img`
  width: 50vw;
  max-width: 150px;
  height: auto;
  cursor: pointer;
  animation: ${popUpAnimation} 1s ease-out;

  &:hover {
    transform: scale(1.05);
  }

  @media (min-width: 1024px) {
    width: 12vw;
    max-width: 250px;
  }

  @media (min-width: 1440px) {
    width: 10vw;
    max-width: 280px;
  }
`;

const Cta = () => {
  const navigate = useNavigate();

  const handleBeliSekarang = () => {
    ReactGA.event({
      category: "END SCREEN",
      action: "ENGAGE_TAP_CTA_BUYNOW",
    });
    window.open(
      'https://ad.doubleclick.net/ddm/trackclk/N536204.3368094ADCOLONYINDONESIA/B32753191.409164084;dc_trk_aid=600901600;dc_trk_cid=225833299;dc_lat=;dc_rdid=%5BDEVICE_ID%5D;tag_for_child_directed_treatment=;tfua=;ltd=;dc_tdv=1',
      '_blank'
    );
  };

  const handleTokoTerdekat = () => {
    ReactGA.event({
      category: "END SCREEN",
      action: "ENGAGE_TAP_STORE_LOCATOR",
    });
    navigate('/StoreLocator');
  };

  return (
    <CtaContainer>
      {/* Full Overlay for copy.png */}
      <CopyOverlay src="/background/copy.png" alt="Overlay Text" />

      {/* Clickable Areas */}
      <LeftArea onClick={handleTokoTerdekat} />
      <RightArea onClick={handleBeliSekarang} />

      <ButtonContainer>
        <Button
          src="/button/TokoTerdekat.png"
          alt="Toko Terdekat"
          onClick={handleTokoTerdekat}
        />
        <Button
          src="/button/BeliSekarang.png"
          alt="Beli Sekarang"
          onClick={handleBeliSekarang}
        />
      </ButtonContainer>
    </CtaContainer>
  );
};

export default Cta;
